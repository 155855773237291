import React, { useState } from "react";
import { useController } from "react-hook-form";
import { FaSearch } from "react-icons/fa";

const RadioSelect = ({
  name,
  control,
  items = [],
  hideState = false,
  width,
  taxonomyType,
  overrideStyling,
  ...props
}) => {
  const { field } = useController({
    name,
    control,
  });

  const [search, setSearch] = useState("");

  const classProps = items.length > 10 && !hideState ? "checkbox-wrapper" : "";
  const widthWrapper = width === "half" ? "50%" : "100%";
  const widthTaxonomy = items.length > 20 ? "50%" : "100%";
  const paddingProps = overrideStyling ? "" : "";

  return (
    <div
      style={{
        width: `${widthWrapper}`,
        paddingTop: `${paddingProps}`,
        paddingBottom: `${paddingProps}`,
      }}
    >
      {!hideState && overrideStyling ? (
        <div className="search-container">
          <input
            className="sub-con-input"
            style={{ display: "block" }}
            placeholder="Search"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          ></input>
          <FaSearch className="search-icon"></FaSearch>
        </div>
      ) : null}
      <div className={`${classProps}`}>
        {!hideState &&
          items.map((item) => {
            if (item?.name?.toLowerCase().includes(search.toLowerCase())) {
              const checked = field.value == item.name;
              return (
                <div
                  key={item.name}
                  style={{ width: `${widthTaxonomy}` }}
                  className="checkbox-container"
                >
                  <label
                    className={`data affiliateTypeCheckbox ${
                      checked ? "checked" : ""
                    }`}
                  >
                    {/*  value={checked ?? false}, className={`data affiliateTypeCheckbox ${value ? "checked" : ""}`} */}
                    <input
                      key={item.name}
                      name={name}
                      className="languageCheckbox"
                      checked={checked ?? false}
                      onChange={() => {
                        if (taxonomyType === "checkbox") {
                          //field.onChange({ ...field.value, item.name });
                          field.onChange(item.name);
                        } else {
                          field.onChange(item.name);
                        }
                      }}
                      type="checkbox"
                    ></input>
                    <span className="radio">
                      <span className="selected"></span>
                    </span>
                    {item.name}
                  </label>
                </div>
              );
            }
          })}
      </div>
      <div style={{ height: "1rem", marginBottom: "20px" }}>
        {!field.value && !hideState && (
          <span className="errMsg">You need to make a choice</span>
        )}
      </div>
    </div>
  );
};

export default RadioSelect;
