import { useEffect, useMemo, useState } from "react";
import _ from "lodash";

import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useForm, useFieldArray } from "react-hook-form";
import { FaQuestionCircle, FaPlusSquare } from "react-icons/fa";
import { VscChromeClose, VscCheck } from "react-icons/vsc";

import ReCaptcha from "./recaptcha/Recaptcha";
import { checkOrAddProtocol, emailPattern, urlPattern, validEmail, validUrl } from "../../../helpers";
import { disabledDepositMethodNames, topDepositMethodNames } from './data/depositMethods'

import TaxonomySelect from "../../TaxonomySelect";
import NavButton from "../../nav-buttons/nav-buttons";
import TaxonomyPreview from "../../TaxonomySelect/taxonomyPreview";
import SignupBonusField from "../../SignupBonusField";
import NewStep from "../../UI/Step/Step";

import Spinner from "../../../images/spinning-loading.gif";

import "antd/dist/antd.css";
import "./ApplicationForm.scss";
import { availableCountries } from './data/countries'
import { topSoftwareProviderNames } from './data/softwareProviders'

const steps = [
  { step: 1, description: "Casino Information" },
  { step: 2, description: "Deposit Methods" },
  { step: 3, description: "Country, Language & Currency" },
  { step: 4, description: "License & Software" },
  { step: 5, description: "Bonuses & Support" },
  { step: 6, description: "Contact" },
  { step: 7, description: "Summary" },
];

export default function ProviderForm() {
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    errors,
    control,
    getValues,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      bonusesOnSignUp: [],
      top3Usp: [{ usp: "usp 1" }, { usp: "" }, { usp: "" }],
    },
  });

  const { fields } = useFieldArray({
    control,
    name: "top3Usp",
  });

  const {
    fields: bonusFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "bonusesOnSignUp",
  });

  const [current, setCurrent] = useState(0);
  const [maxDirtyStep, setMaxDirtyStep] = useState(0);
  const history = useHistory();

  useEffect(() => {
    if (maxDirtyStep <= current) {
      setMaxDirtyStep(current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);

  const next = () => {
    setCurrent(current + 1);
    document
      .getElementById("form-container")
      .scrollIntoView({ behavior: "smooth" });
  };

  const prev = () => {
    setCurrent(current - 1);
    document
      .getElementById("form-container")
      .scrollIntoView({ behavior: "smooth" });
  };

  const submitDate = format(new Date(), "yyyy-MM-dd");

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingToAllan, setIsLoadingToAllan] = useState(false);
  const [error, setError] = useState("");
  const [languageData, setLanguageData] = useState([]);
  const [countriesData, setCountriesData] = useState([]);
  const [currenciesData, setCurrenciesData] = useState([]);
  const [softwareData, setSoftwareData] = useState([]);
  const [topSoftwareData, setTopSoftwareData] = useState([]);
  const [depositMethods, setDepositMethods] = useState([]);
  const [topDepositMethods, setTopDepositMethods] = useState([]);
  const [bonusData, setBonusData] = useState([]);
  const [licensesData, setLicensesData] = useState([]);
  const [supportData, setSupportData] = useState([]);
  const [restrictedCountriesData, setRestrictedCountriesData] = useState([]);
  const [casinoTypesData, setCasinoTypesData] = useState([]);
  const [top3Usp, setTop3Usp] = useState([]);
  const watchAll = watch();

  var [recaptchaStatus, setRecaptchaStatus] = useState(false);
  const [recaptchaKey, setRecaptchaKey] = useState();

  const [fileInputState, setFileInputState] = useState("");
  const [previewSource, setPreviewSource] = useState("");

  const validateSteps = useMemo(() => {
    const percetages = [34, 100, 50, 100, 100, 100, 100];

    const values = getValues();

    const toInt = value => {
      return value ? 1 : 0;
    };

    const toPercent = values => {
      const sum = values.reduce((sum, cur) => (sum += cur), 0);
      return parseInt((sum / values.length) * 100, 10);
    };

    // Step1
    percetages[0] = toPercent([
      toInt(!_.isEmpty(values?.casinoName)),
      toInt(validUrl(values?.casinoDomain)),
      toInt(!_.isEmpty(values?.yearEstablished)),
      toInt(!_.isEmpty(values?.owner)),
      toInt(!_.isEmpty(values?.casinoTypes)),
      toInt(previewSource),
    ]);

    // step 2
    percetages[1] = toPercent([
      toInt(!_.isEmpty(values?.depositMethods))
    ]);

    // Step 3
    percetages[2] = toPercent([
      toInt(!_.isEmpty(values?.currencies)),
      toInt(!_.isEmpty(values?.languages)),
      toInt(!_.isEmpty(values?.countries)),
      toInt(!_.isEmpty(values?.restrictedCountries)),
    ]);

    // Step 4
    percetages[3] = toPercent([
      toInt(!_.isEmpty(values?.licenses)),
      toInt(!_.isEmpty(values?.software)),
    ]);

    // Step 5
    percetages[4] = toPercent([
      toInt(!_.isEmpty(values?.supportTypes)),
      toInt(!_.isEmpty(values?.bonuses)),
      toInt(!_.isEmpty(values?.freespins)),
      toInt(!_.isEmpty((values?.top3Usp || [])[0]?.usp)),
    ]);

    // Step 6
    percetages[5] = toPercent([
      toInt(!_.isEmpty(values?.companyLegalName)),
      toInt(!_.isEmpty(values?.companyRegNumber)),
      toInt(!_.isEmpty(values?.companyVatNumber)),
      toInt(!_.isEmpty(values?.companyAddress)),

      toInt(!_.isEmpty(values?.contactName)),
      toInt(!_.isEmpty(values?.contactEmail)),
      toInt(!_.isEmpty(values?.contactSkype)),
    ]);

    return percetages;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);

  const handleFileInput = e => {
    const logo = e.target.files[0];

    if (
      !(
        logo.type === "image/png" ||
        logo.type === "image/svg" ||
        logo.type === "image/svg+xml"
      )
    ) {
      alert("Approved formats either .svg or .png");
      return;
    }

    if (logo) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewSource(reader.result);
      };
      reader.readAsDataURL(logo);
    }
  };

  const uploadLogo = async base64Image => {
    let logoData = {};
    try {
      await fetch("/api/cloudinary/upload", {
        method: "POST",
        body: JSON.stringify({ data: base64Image }),
        headers: { "content-type": "application/json" },
      })
        .then(res => res.json())
        .then(data => {
          logoData = data.url;
        });
      return logoData;
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitFile = async () => {
    if (!previewSource) return;
    return await uploadLogo(previewSource);
  };

  useEffect(() => {
    fetchApiQuery();
  }, []);

  useEffect(() => {
    if (!isLoading) {
      window.onscroll = function () {
        addSticky();
      };

      var steps = document.getElementById("steps");
      var stepsLogo = document.getElementById("steps-logo");
      var container = document.getElementById("extra-wrapper");
      var sticky = steps?.offsetTop;

      // eslint-disable-next-line no-inner-declarations
      function addSticky() {
        if (window.pageYOffset >= sticky) {
          stepsLogo.classList.remove("hidden");
          steps.classList.remove("notSticky");
          steps.classList.add("sticky");
          container.classList.add("ifSticky");
        } else {
          stepsLogo.classList.add("hidden");
          steps.classList.add("notSticky");
          steps.classList.remove("sticky");
          container.classList.remove("ifSticky");
        }
      }
    }
  }, [isLoading]);

  function fetchApiQuery() {
    setIsLoading(true);
    setError("");
    const fetchUrl = "/api/taxonomies";
    axios
      .get(fetchUrl)
      .then(response => {
        const [topSoftwareProviders, softwareProviders] =
          separateTaxonomyAndTopTaxonomyItems(
            response.data.software.children.sort((a, b) =>
              a?.name.localeCompare(b?.name),
            ),
            topSoftwareProviderNames
          );
        setSoftwareData(softwareProviders);
        setTopSoftwareData(topSoftwareProviders);
        setLanguageData(
          response.data.languages.children.sort((a, b) =>
            a?.name.localeCompare(b?.name),
          ),
        );
        setCountriesData(
          response.data.countries.children
            .filter(el => availableCountries.includes(el.name))
            .sort((a, b) => a?.name.localeCompare(b?.name))
        );
        setCurrenciesData(
          response.data.acceptedCurrencies.children.sort((a, b) =>
            a?.name.localeCompare(b?.name),
          ),
        );
        const [topDepositMethods, depositMethods] =
          separateTaxonomyAndTopTaxonomyItems(
            response.data.depositMethods.children.sort((a, b) =>
              a?.name.localeCompare(b?.name),
            ),
            topDepositMethodNames,
            disabledDepositMethodNames
          );
        setDepositMethods(depositMethods);
        setTopDepositMethods(topDepositMethods);
        setBonusData(
          response.data.bonuses.children.sort((a, b) =>
            a?.name.localeCompare(b?.name),
          ),
        );
        setLicensesData(
          response.data.licenses.children.sort((a, b) =>
            a?.name.localeCompare(b?.name),
          ),
        );
        setSupportData(
          response.data.supportTypes.children.sort((a, b) =>
            a?.name.localeCompare(b?.name),
          ),
        );
        setRestrictedCountriesData(
          response.data.restrictedCountries.sort((a, b) =>
            a?.name.localeCompare(b?.name),
          ),
        );
        setCasinoTypesData(
          response.data.casinoTypes.children.sort((a, b) =>
            a?.name.localeCompare(b?.name),
          ),
        );

        setIsLoading(false);
      })
      .catch(function (error) {
        setIsLoading(false);
        setError("Error: Unable to load data from server...");
        console.log(error);
      });
  }

  const separateTaxonomyAndTopTaxonomyItems = (allTaxonomyItems, topTaxonomyItemNames, disabledTaxonomyItemNames = []) => {
    const enabledTaxonomyItems = allTaxonomyItems.filter(el => !disabledTaxonomyItemNames.includes(el.name));
    let taxonomyItems = enabledTaxonomyItems.filter(el => !topTaxonomyItemNames.includes(el.name));
    let topTaxonomyItems = enabledTaxonomyItems.filter(el => topTaxonomyItemNames.includes(el.name));
    topTaxonomyItems.sort(
      (a, b) => topTaxonomyItemNames.findIndex(e => e.toLowerCase() === a.name.toLowerCase())
        - topTaxonomyItemNames.findIndex(e => e.toLowerCase() === b.name.toLowerCase()),
      (a, b) => a?.name.localeCompare(b?.name),
    );
    return [topTaxonomyItems, taxonomyItems];
  }

  const postToAllan = async providerData => {
    setIsLoadingToAllan(true);
    const logoUrl = await handleSubmitFile();

    axios
      .post("/api/providers", {
        providerData: {
          ...providerData,
          casinoDomain: checkOrAddProtocol(providerData.casinoDomain),
          signupLinkAffiliate: checkOrAddProtocol(providerData.signupLinkAffiliate),
          casinoLogo: logoUrl,
          topUsp: top3Usp,
          createdAt: submitDate,
        },
        key: recaptchaKey,
      })
      .then(() => history.replace("/success"))
      .catch(err => {
        console.log(err);
        alert(
          "Registration process failed. Make sure you submitted the ReCaptcha.",
        );
      })
      .finally(() => setIsLoadingToAllan(false));
  };

  const handleRecaptcha = async () => {
    setRecaptchaStatus(true);
  };

  const onError = (errors, e) => console.log(errors, e);

  if (error) {
    return (
      <div
        style={{
          width: "700px",
          height: "400px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "white",
          marginTop: "50px",
          marginBottom: "200px",
          fontSize: "20px",
        }}
      >
        {error}
      </div>
    );
  }

  if (isLoading) {
    return (
      <div
        style={{
          width: "700px",
          height: "400px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "white",
          marginTop: "50px",
          marginBottom: "200px",
        }}
      >
        <img src={Spinner} height={100} alt="spinner" />
      </div>
    );
  }

  return (
    <div id="form-container">
      <div id="steps">
        <img
          id="steps-logo"
          className="steps-logo hidden"
          style={{
            height: "25px",
            marginLeft: "16px",
            marginBottom: "40px",
          }}
          alt="OnlineCasinoRank.se"
          src="https://onlinecasinorank.se/assets/onlinecasinorank-se_logo?transformations=f_auto,c_fill,q_auto,fl_progressive"
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: "70px",
            paddingRight: "70px",
          }}
        >
          {steps.map((item, index) => (
            <NewStep
              key={item.step}
              maxDirtyStep={maxDirtyStep + 1}
              progress={validateSteps[index]}
              current={current + 1}
              step={item.step}
              description={item.description}
              onClick={() => setCurrent(item.step - 1)}
            />
          ))}
        </div>
      </div>

      <div id="steps">
        <div className="small-steps">
          <h4 className="steps-header-mobile">
            {current === 0 && <span>Step</span>} {current + 1} of 9
          </h4>
        </div>
      </div>
      <div className="mobile-steps"/>

      <div id="extra-wrapper" className="extra-wrapper">
        <form onSubmit={handleSubmit(postToAllan, onError)}>
          <div
            style={{ display: current === 0 ? "block" : "none" }}
            className="sub-container brand-information"
          >
            <h3>Casino Information</h3>
            <div className="checkbox-wrapper first-step">
              <label>Casino Brand Name</label>
              <br />
              <span
                style={{
                  fontSize: "0.75rem",
                  opacity: "0.45",
                  margin: "10px 0",
                }}
                className="description-text"
              >
                Example: EnergyCasino / Betsson / LeoVegas / La Fiesta Casino{" "}
              </span>
              <br />
              <input
                type="text"
                name="casinoName"
                placeholder="My Casino"
                className="main-input"
                ref={register({ required: true })}
              />
              {errors.casinoName && errors.casinoName.type === "required" && (
                <span className="errMsg">Casino name is required.</span>
              )}
              <div className="preview-container">
                <label>Casino Logo</label>
                <br />
                <div className="image-container">
                  {previewSource && (
                    <img
                      id="preview"
                      src={previewSource}
                      alt=""
                      style={{ height: "100px" }}
                    />
                  )}
                  <br />
                  <label className="upload-file">
                    <input
                      className="choose-file"
                      type="file"
                      name="casinoLogo"
                      id="image"
                      value={fileInputState}
                      accept="image/*"
                      onChange={handleFileInput}
                    />
                    Choose File
                  </label>
                  {!previewSource && (
                    <span className="errMsg" style={{ fontSize: "12px" }}>
                      You need to upload your logo. Approved formats either .svg
                      or .png. Transparent background required. Max size:
                      1000x1000.
                    </span>
                  )}
                </div>
              </div>
              <label>Casino Domain</label>
              <br />
              <input
                type="text"
                name="casinoDomain"
                placeholder="mycasino.org"
                className="main-input"
                ref={register({ required: true, pattern: urlPattern })}
              />
              {errors.casinoDomain &&
                errors.casinoDomain.type === "required" && (
                  <span className="errMsg">Domain is required.</span>
                )}
              {errors.casinoDomain &&
                errors.casinoDomain.type === "pattern" && (
                  <span className="errMsg">You need to enter a valid URL.</span>
                )}


              <br />
              <label>Year Established</label>
              <br />
              <input
                type="number"
                name="yearEstablished"
                placeholder="20XX"
                className="main-input"
                ref={register({ required: true })}
              />
              {errors.yearEstablished &&
                errors.yearEstablished.type === "required" && (
                  <span className="errMsg">Year established is required.</span>
                )}
              <br />
              <label>The Casino Owner</label>
              <br />
              <input
                type="text"
                name="owner"
                placeholder="Casino Group"
                className="main-input"
                ref={register({ required: true })}
              />
              {errors.owner && errors.owner.type === "required" && (
                <span className="errMsg">Casino owner is required.</span>
              )}
              <br />
            </div>
          </div>
          <div style={{ display: current === 0 ? "block" : "none" }}>
            <TaxonomySelect
              control={control}
              name={"casinoTypes"}
              taxonomyItems={casinoTypesData}
              canAddManually={true}
              canSelectAll={false}
              title="Casino Type"
              desc="Please select the kind of casinos you have:"
              helpImage="https://res.cloudinary.com/providerlogos/image/upload/v1635937033/helpcasinotypes_ub8tnw.png"
            />
          </div>

          <div style={{ display: current === 1 ? "block" : "none" }}>
            <TaxonomySelect
              control={control}
              name={"depositMethods"}
              taxonomyItems={depositMethods}
              topTaxonomyItems={topDepositMethods}
              canAddManually={true}
              canSelectAll={false}
              title="Deposit Methods"
              desc="Please select all of the deposit methods you accept at your casino"
              helpImage="https://res.cloudinary.com/providerlogos/image/upload/v1612538712/helpdeposit_llqsg3.png"
            />
          </div>

          <div style={{ display: current === 2 ? "block" : "none" }}>
            <TaxonomySelect
              control={control}
              name={"currencies"}
              taxonomyItems={currenciesData}
              canAddManually={false}
              canSelectAll={false}
              title="Accepted Currencies"
              desc="What currencies are accepted at your casino?"
              helpImage="https://res.cloudinary.com/providerlogos/image/upload/v1612538743/helpcurrencies_ban4as.png"
            />
            <TaxonomySelect
              control={control}
              name={"languages"}
              taxonomyItems={languageData}
              canAddManually={false}
              canSelectAll={false}
              title="Supported Languages"
              desc="What languages are your casino website translated to?"
              helpImage="https://res.cloudinary.com/providerlogos/image/upload/v1612538743/helplanguages_fesama.png"
            />
            <TaxonomySelect
              control={control}
              name={"countries"}
              taxonomyItems={countriesData}
              canSelectAll={true}
              setValue={setValue}
              canAddManually={false}
              maxNumberToSelect={10}
              title="TOP countries (Choose max. 10 TOP countries for your brand)"
              desc="Please, select the most important countries, where your brand is accessible"
              helpImage="https://res.cloudinary.com/providerlogos/image/upload/v1612538743/helpcountries_egxkkq.png"
            />
            <TaxonomySelect
              control={control}
              name={"restrictedCountries"}
              taxonomyItems={restrictedCountriesData}
              canSelectAll={true}
              setValue={setValue}
              canAddManually={false}
              title="Restricted Countries"
              desc="Please select all the countries where your brand is NOT accessible"
              helpImage="https://res.cloudinary.com/providerlogos/image/upload/v1612538743/helpcountries_egxkkq.png"
            />
          </div>

          <div style={{ display: current === 3 ? "block" : "none" }}>
            <TaxonomySelect
              control={control}
              name={"licenses"}
              taxonomyItems={licensesData}
              canAddManually={true}
              canSelectAll={false}
              title="Licenses"
              desc=""
              helpImage="https://res.cloudinary.com/providerlogos/image/upload/v1612538743/helplicenses_ptuihy.png"
            />
            <TaxonomySelect
              control={control}
              name={"software"}
              taxonomyItems={softwareData}
              topTaxonomyItems={topSoftwareData}
              canAddManually={true}
              canSelectAll={false}
              title="Software Providers"
              desc="Which software providers are powering your games?"
              helpImage="https://res.cloudinary.com/providerlogos/image/upload/v1612538743/helpsoftware_akiwea.png"
            />
          </div>

          <div style={{ display: current === 4 ? "block" : "none" }}>
            <TaxonomySelect
              control={control}
              name={"supportTypes"}
              taxonomyItems={supportData}
              canAddManually={true}
              canSelectAll={false}
              title="Support Types"
              desc=""
              helpImage="https://res.cloudinary.com/providerlogos/image/upload/v1612538712/helpsupport_yklogb.png"
            />
            <TaxonomySelect
              control={control}
              name={"bonuses"}
              taxonomyItems={bonusData}
              canAddManually={true}
              canSelectAll={false}
              title="Bonus Types"
              desc=""
              helpImage="https://res.cloudinary.com/providerlogos/image/upload/v1612538743/helpbonustax_zdy2ba.png"
            />

            <div
              style={{ display: current === 4 ? "block" : "none" }}
              className="sub-container"
            >
              <h3 style={{ display: "inline" }}>Bonus Amount</h3>
              <div style={{ display: "inline" }} className="help">
                <a href="javascript:void(0)">
                  <FaQuestionCircle />
                  <span>
                    <img
                      src="https://res.cloudinary.com/providerlogos/image/upload/v1612526513/helpbonuss_e1bvjg.png"
                      alt=""
                    />
                  </span>
                </a>
              </div>
              <div
                className="search-container"
                style={{ border: "none" }}
              />
              <div id="bonuses" className="checkbox-wrapper">
                <label>Number of Freespins</label>
                <br />
                <input
                  className="bonus-inputs"
                  name="freespins"
                  placeholder="Freespins"
                  ref={register}
                />
                <div id="bonusInputs">
                  <label>Cash bonus amount</label>
                  <br />
                  {bonusFields.map((item, index) => {
                    const removeField = () => {
                      // console.log(index);
                      // bonusFields.splice(index, 1);
                      // console.log(bonusFields);
                      remove(index);
                    };
                    return (
                      <SignupBonusField
                        removeField={removeField}
                        array={bonusFields}
                        item={item}
                        index={index}
                        // register={register}
                        control={control}
                        currenciesData={currenciesData}
                        countriesData={countriesData}
                      />
                    );
                  })}
                  <label
                    style={{
                      fontSize: "20px",
                      marginBottom: "-8px",
                      marginRight: "10px",
                    }}
                  >
                    Add more fields
                  </label>
                  <FaPlusSquare
                    className="addTaxBtn"
                    onClick={() => {
                      append({ amount: "", currency: "", countries: "" });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            style={{ display: current === 5 ? "block" : "none" }}
            className="sub-container"
            id="contact-info"
          >
            <h3>Company Information</h3>
            <div className="checkbox-wrapper" style={{ height: "auto" }}>
              <label>Company Legal Name</label>
              <br />
              <input
                type="text"
                name="companyLegalName"
                placeholder=""
                className="main-input"
                ref={register({ required: true })}
              />
              {errors.companyLegalName &&
                errors.companyLegalName.type === "required" && (
                  <span className="errMsg">This field is required.</span>
                )}
              <br />
              <label>Company Registration Number</label>
              <br />
              <input
                type="text"
                name="companyRegNumber"
                placeholder=""
                className="main-input"
                ref={register({
                  required: true,
                })}
              />
              {errors.companyRegNumber &&
                errors.companyRegNumber.type === "required" && (
                  <span className="errMsg">This field is required.</span>
                )}
              <br />
              <label>VAT Number</label>
              <br />
              <input
                type="text"
                name="companyVatNumber"
                placeholder=""
                className="main-input"
                ref={register}
              />
              <br />
              <label>Company Address</label>
              <br />
              <input
                type="text"
                name="companyAddress"
                placeholder=""
                className="main-input"
                ref={register({
                  required: true,
                })}
              />
              {errors.companyAddress &&
                errors.companyAddress.type === "required" && (
                  <span className="errMsg">This field is required.</span>
                )}
            </div>
          </div>

          <div
            style={{ display: current === 5 ? "block" : "none" }}
            className="sub-container"
            id="contact-info"
          >
            <h3>Account Manager Contact Information</h3>
            <div className="checkbox-wrapper" style={{ height: "auto" }}>
              <label>Name</label>
              <br />
              <input
                type="text"
                name="contactName"
                placeholder=""
                className="main-input"
                ref={register({ required: true })}
              />
              {errors.contactName && errors.contactName.type === "required" && (
                <span className="errMsg">This field is required.</span>
              )}
              <br />
              <label>Email</label>
              <br />
              <input
                type="text"
                name="contactEmail"
                placeholder=""
                className="main-input"
                ref={register({
                  required: true,
                  pattern: emailPattern,
                })}
              />
              {errors.contactEmail &&
                errors.contactEmail.type === "required" && (
                  <span className="errMsg">This field is required.</span>
                )}
              {errors.contactEmail &&
                errors.contactEmail.type === "pattern" && (
                  <span className="errMsg">
                    You need to enter a valid email.
                  </span>
                )}
              <br />
              <label>Skype</label>
              <br />
              <input
                type="text"
                name="contactSkype"
                placeholder=""
                className="main-input"
                ref={register}
              />
            </div>
          </div>

          <div
            style={{ display: current === 4 ? "block" : "none" }}
            className="sub-container"
          >
            <h3 style={{ display: "inline" }}>What are your top 3 USP?</h3>
            <div style={{ display: "inline" }} className="help">
              <a href="#?">
                <FaQuestionCircle />
                <span>
                  <img
                    src="https://res.cloudinary.com/providerlogos/image/upload/v1612776433/helppros_ovk1sy.png"
                    alt=""
                  />
                </span>
              </a>
            </div>
            <div
              style={{
                fontSize: "0.75rem",
                opacity: "0.45",
                margin: "10px 0",
              }}
              className="description-text"
            >
              Atleast ONE selling point is required.
            </div>
            <div id="usp" className="checkbox-wrapper">
              {fields.map((item, i) => {
                let errorName = `top3Usp[${i}].usp`;
                return (
                  <div key={i}>
                    <label style={{ marginRight: "5px" }}>{i + 1}.</label>
                    {i < 1 ? (
                      <input
                        type="text"
                        name={errorName}
                        placeholder={`USP ${i + 1}`}
                        className="main-input topUsp"
                        ref={register({ required: true })}
                      />
                    ) : (
                      <input
                        type="text"
                        name={errorName}
                        placeholder={`USP ${i + 1}`}
                        className="main-input topUsp"
                        ref={register({ required: false })}
                      />
                    )}
                    {errors?.top3Usp?.[i]?.usp &&
                      errors?.top3Usp?.[i]?.usp?.type === "required" && (
                        <span className="errMsg">This field is required.</span>
                      )}
                    <br />
                  </div>
                );
              })}
            </div>
          </div>

          <div
            className="preview-application sub-container"
            style={{ display: current === 6 ? "block" : "none" }}
          >
            <h3>Your application</h3>
            <div className="brand-information">
              <div>
                <h4>Casino information</h4>
                <InfoRow>
                  <div>
                    <span style={{ color: "red", marginRight: 5 }}>*</span>
                    <span>
                      Casino name:{" "}
                      {watchAll.casinoName || (
                        <span
                          className="errMsg NA"
                          onClick={() => setCurrent(0)}
                        >
                          Casino name is required - Click to go back and fix
                          that!
                        </span>
                      )}
                    </span>
                  </div>
                  <StatusIcon isValid={!!watchAll.casinoName} />
                </InfoRow>

                <InfoRow>
                  <div>
                    <span style={{ color: "red", marginRight: 5 }}>*</span>
                    <span>
                      Owner:{" "}
                      {watchAll.owner || (
                        <span
                          className="errMsg NA"
                          onClick={() => setCurrent(0)}
                        >
                          Casino owner is required - Click to go back and fix
                          that!
                        </span>
                      )}
                    </span>
                  </div>
                  <StatusIcon isValid={!!watchAll.owner} />
                </InfoRow>

                <InfoRow>
                  <div>
                    <span style={{ color: "red", marginRight: 5 }}>*</span>
                    <span>
                      Domain:{" "}
                      {validUrl(watchAll.casinoDomain) ? (
                        checkOrAddProtocol(watchAll.casinoDomain)
                      ) : (
                        <span
                          className="errMsg NA"
                          onClick={() => setCurrent(0)}
                        >
                          Domain is required - Click to go back and fix that!
                        </span>
                      )}
                    </span>
                  </div>
                  <StatusIcon isValid={validUrl(watchAll.casinoDomain)} />
                </InfoRow>

                <InfoRow>
                  <div>
                    <span style={{ color: "red", marginRight: 5 }}>*</span>
                    <span>
                      Year established:{" "}
                      {watchAll.yearEstablished || (
                        <span
                          className="errMsg NA"
                          onClick={() => setCurrent(0)}
                        >
                          Year established is required - Click to go back and
                          fix that!
                        </span>
                      )}
                    </span>
                  </div>
                  <StatusIcon isValid={!!watchAll.yearEstablished} />
                </InfoRow>

                <InfoRow>
                  <div>
                    <span style={{ color: "red", marginRight: 5 }}>*</span>
                    <span>Casino Logo:</span>
                    {previewSource ? (
                      <div className="image-container">
                        <img
                          id="preview"
                          src={previewSource}
                          alt="Logo"
                          style={{ height: "100px" }}
                        />
                      </div>
                    ) : (
                      <span className="errMsg NA" onClick={() => setCurrent(0)}>
                        Logo is required - Click to go back and fix that!
                      </span>
                    )}
                  </div>
                  <StatusIcon isValid={!!previewSource} />
                </InfoRow>

                <InfoRow>
                  <div>
                    <span style={{ color: "red", marginRight: 5 }}>*</span>
                    <span>Casino Types: </span>
                    <TaxonomyPreview
                      step={0}
                      taxonomies={watchAll.casinoTypes}
                      setCurrent={setCurrent}
                      message={"Casino type is required"}
                    />
                  </div>
                  <StatusIcon isValid={!_.isEmpty(watchAll.casinoTypes)} />
                </InfoRow>
                <hr/>
              </div>
              <div>
                <h4>Deposit</h4>

                <InfoRow>
                  <div>
                    <span style={{ color: "red", marginRight: 5 }}>*</span>
                    <span>
                      Deposit methods:{" "}
                      <TaxonomyPreview
                        step={1}
                        taxonomies={watchAll.depositMethods}
                        setCurrent={setCurrent}
                        message={"Deposit is required"}
                      />
                    </span>
                  </div>
                  <StatusIcon isValid={!_.isEmpty(watchAll.depositMethods)} />
                </InfoRow>
                <hr/>
              </div>
              <div>
                <h4>Countries, languages and currencies</h4>

                <InfoRow>
                  <div>
                    <span>
                      <span style={{ color: "red", marginRight: 5 }}>*</span>
                      Accepted currencies:{" "}
                      <TaxonomyPreview
                        step={2}
                        taxonomies={watchAll.currencies}
                        setCurrent={setCurrent}
                        message={"Currencies is required"}
                      />
                    </span>
                  </div>
                  <StatusIcon isValid={!_.isEmpty(watchAll.currencies)} />
                </InfoRow>

                <InfoRow>
                  <div>
                    <span style={{ color: "red", marginRight: 5 }}>*</span>
                    <span>
                      Countries:{" "}
                      <TaxonomyPreview
                        step={2}
                        taxonomies={watchAll.countries}
                        setCurrent={setCurrent}
                        message={"Countries is required"}
                      />
                    </span>
                  </div>
                  <StatusIcon isValid={!_.isEmpty(watchAll.countries)} />
                </InfoRow>

                <InfoRow>
                  <div>
                    <span style={{ color: "red", marginRight: 5 }}>*</span>
                    <span>
                      Restricted Countries:{" "}
                      <TaxonomyPreview
                        step={2}
                        taxonomies={watchAll.restrictedCountries}
                        setCurrent={setCurrent}
                        message={"Restricted is required"}
                      />
                    </span>
                  </div>
                  <StatusIcon
                    isValid={!_.isEmpty(watchAll.restrictedCountries)}
                  />
                </InfoRow>

                <InfoRow>
                  <div>
                    <span style={{ color: "red", marginRight: 5 }}>*</span>
                    Languages:{" "}
                    <TaxonomyPreview
                      step={2}
                      taxonomies={watchAll.languages}
                      setCurrent={setCurrent}
                      message={"Languages is required"}
                    />
                  </div>
                  <StatusIcon isValid={!_.isEmpty(watchAll.languages)} />
                </InfoRow>
                <hr/>
              </div>
              <div>
                <h4>Licenses and software</h4>

                <InfoRow>
                  <div>
                    <span style={{ color: "red", marginRight: 5 }}>*</span>
                    <span>
                      Licenses:{" "}
                      <TaxonomyPreview
                        step={3}
                        taxonomies={watchAll.licenses}
                        setCurrent={setCurrent}
                        message={"Licenses is required"}
                      />
                    </span>
                  </div>
                  <StatusIcon isValid={!_.isEmpty(watchAll.licenses)} />
                </InfoRow>

                <InfoRow>
                  <div>
                    <span style={{ color: "red", marginRight: 5 }}>*</span>
                    <span>
                      Software:{" "}
                      <TaxonomyPreview
                        step={3}
                        taxonomies={watchAll.software}
                        setCurrent={setCurrent}
                        message={"Software is required"}
                      />
                    </span>
                  </div>
                  <StatusIcon isValid={!_.isEmpty(watchAll.software)} />
                </InfoRow>

                <hr/>
              </div>
              <div>
                <h4>Bonuses and support</h4>

                <InfoRow>
                  <div>
                    <span style={{ color: "red", marginRight: 5 }}>*</span>
                    <span>
                      Bonuses:{" "}
                      <TaxonomyPreview
                        step={4}
                        taxonomies={watchAll.bonuses}
                        setCurrent={setCurrent}
                        message={"Bonuses is required"}
                      />
                    </span>
                  </div>
                  <StatusIcon isValid={!_.isEmpty(watchAll.bonuses)} />
                </InfoRow>

                <InfoRow>
                  <div>
                    <span style={{ color: "red", marginRight: 5 }}>*</span>
                    <span>
                      Support:{" "}
                      <TaxonomyPreview
                        step={4}
                        taxonomies={watchAll.supportTypes}
                        setCurrent={setCurrent}
                        message={"Support is required"}
                      />
                    </span>
                  </div>
                  <StatusIcon isValid={!_.isEmpty(watchAll.supportTypes)} />
                </InfoRow>

                <hr/>
              </div>
              <div>
                <h4>Bonus amount</h4>

                <InfoRow>
                  <div>
                    <span>
                      Freespins:{" "}
                      {watchAll.freespins || (
                        <span onClick={() => setCurrent(4)}>0</span>
                      )}
                    </span>
                  </div>
                  <StatusIcon optional />
                </InfoRow>

                <InfoRow>
                  <div>
                    <span>
                      Bonus on signup:{" "}
                      {watchAll.bonusesOnSignUp
                        ?.reduce((acc, item) => {
                          if (item?.amount) {
                            acc.push(item?.amount + " " + item.currency);
                          }
                          return acc;
                        }, [])
                        .join?.(", ") || (
                        <span onClick={() => setCurrent(4)}>0</span>
                      )}
                    </span>
                  </div>
                  <StatusIcon optional />
                </InfoRow>

                <hr/>
              </div>
              <div>
                <h4>Company information</h4>

                <InfoRow>
                  <div>
                    <span style={{ color: "red", marginRight: 5 }}>*</span>
                    <span>
                      Company Name:{" "}
                      {watchAll.companyLegalName || (
                        <span
                          className="errMsg NA"
                          onClick={() => setCurrent(5)}
                        >
                          Company name - Click to go back and fix that!
                        </span>
                      )}
                    </span>
                  </div>
                  <StatusIcon isValid={!_.isEmpty(watchAll.companyLegalName)} />
                </InfoRow>

                <InfoRow>
                  <div>
                    <span style={{ color: "red", marginRight: 5 }}>*</span>
                    <span>
                      Company Address:{" "}
                      {watchAll.companyAddress || (
                        <span
                          className="errMsg NA"
                          onClick={() => setCurrent(5)}
                        >
                          Company Address is required - Click to go back and fix
                          that!
                        </span>
                      )}
                    </span>
                  </div>
                  <StatusIcon isValid={!_.isEmpty(watchAll.companyAddress)} />
                </InfoRow>

                <InfoRow>
                  <div>
                    <span style={{ color: "red", marginRight: 5 }}>*</span>
                    <span>
                      Company Registration Number:{" "}
                      {watchAll.companyRegNumber || (
                        <span
                          className="errMsg NA"
                          onClick={() => setCurrent(5)}
                        >
                          Registration num is required - Click to go back and
                          fix that!
                        </span>
                      )}
                    </span>
                  </div>
                  <StatusIcon isValid={!_.isEmpty(watchAll.companyRegNumber)} />
                </InfoRow>

                <hr/>
              </div>
              <div>
                <h4>Contact information</h4>

                <InfoRow>
                  <div>
                    <span style={{ color: "red", marginRight: 5 }}>*</span>
                    <span>
                      Name:{" "}
                      {watchAll.contactName || (
                        <span
                          className="errMsg NA"
                          onClick={() => setCurrent(5)}
                        >
                          Name is required- Click to go back and fix that!
                        </span>
                      )}
                    </span>
                  </div>
                  <StatusIcon isValid={!_.isEmpty(watchAll.contactName)} />
                </InfoRow>

                <InfoRow>
                  <div>
                    <span style={{ color: "red", marginRight: 5 }}>*</span>
                    <span>
                      Email:{" "}
                      {validEmail(watchAll.contactEmail) ? (
                        watchAll.contactEmail
                      ) : (
                        <span
                          className="errMsg NA"
                          onClick={() => setCurrent(5)}
                        >
                          Contact Email should be valid - Click to go back and
                          fix that!
                        </span>
                      )}
                    </span>
                  </div>
                  <StatusIcon isValid={validEmail(watchAll.contactEmail)} />
                </InfoRow>

                <InfoRow>
                  <div>
                    <span>Skype: {watchAll.contactSkype}</span>
                  </div>
                  <StatusIcon optional />
                </InfoRow>

                <hr/>
              </div>
              <div>
                <h4>Additional information</h4>

                <InfoRow>
                  <div>
                    <span style={{ color: "red", marginRight: 5 }}>*</span>
                    <span>
                      Top 3 USP:{" "}
                      {watchAll.top3Usp
                        ?.reduce((acc, item) => {
                          if (item?.usp) {
                            acc.push(item?.usp);
                          }
                          return acc;
                        }, [])
                        .join?.(", ") || (
                        <span
                          className="errMsg NA"
                          onClick={() => setCurrent(4)}
                        >
                          Top 3 USP is required - Click to go back and fix that!
                        </span>
                      )}
                    </span>
                  </div>
                  <StatusIcon
                    isValid={
                      !!watchAll.top3Usp
                        ?.reduce((acc, item) => {
                          if (item?.usp) {
                            acc.push(item?.usp);
                          }
                          return acc;
                        }, [])
                        .join?.(", ")
                    }
                  />
                </InfoRow>
              </div>
            </div>
            <span style={{ color: "red", marginRight: 5 }}>*</span> Required
            fields
          </div>
          {current === 6 && (
            <div
              style={{ margin: "20px auto", width: "304px" }}
              onChange={handleRecaptcha}
            >
              <ReCaptcha onChange={key => setRecaptchaKey(key)} />
            </div>
          )}
          <NavButton
            next={next}
            prev={prev}
            watchAll={watchAll}
            handleSubmit={handleSubmit}
            postToAllan={postToAllan}
            previewSource={previewSource}
            current={current}
            recaptchaKey={recaptchaKey}
            isLoadingToAllan={isLoadingToAllan}
          />
        </form>
      </div>
    </div>
  );
}

function StatusIcon({ isValid, optional = false }) {
  if (optional) {
    return <span style={{ color: "grey", fontSize: "14px" }}>Optional</span>;
  }

  return isValid ? <SuccessIcon /> : <CloseIcon />;
}

function InfoRow({ children }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: 5,
      }}
    >
      {children}
    </div>
  );
}

function CloseIcon() {
  return (
    <div
      style={{
        width: 17,
        height: 17,
        borderRadius: 17,
        border: "1px solid #FF7972",
        justifyContent: "center",
        alignItems: "center",
        paddingBottom: 5,
        backgroundColor: "#FFF0EF",
        textAlign: "center",
      }}
    >
      <VscChromeClose size={10} color="#FF7972" style={{ marginBottom: 3 }} />
    </div>
  );
}

function SuccessIcon() {
  return (
    <div
      style={{
        width: 17,
        height: 17,
        borderRadius: 17,
        border: "1px solid #21A260",
        justifyContent: "center",
        alignItems: "center",
        paddingBottom: 5,
        backgroundColor: "#E8FFF3",
        textAlign: "center",
      }}
    >
      <VscCheck size={10} color="#21A260" style={{ marginBottom: 3 }} />
    </div>
  );
}
