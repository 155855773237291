import React, { useState } from 'react'
import classNames from 'classnames'
import { useController } from 'react-hook-form'
import { FaQuestionCircle, FaSearch } from 'react-icons/fa'
import { IoAddCircleOutline } from 'react-icons/io5'
import { TiDelete } from 'react-icons/ti'
import { Button } from 'antd'

import './index.scss'

function removeUndefined(obj) {
  Object.keys(obj).forEach(key =>
    obj[key] === undefined ? delete obj[key] : {},
  )
  return obj
}

const TaxonomySelect = ({
  name,
  taxonomyItems,
  topTaxonomyItems = [],
  control,
  helpImage,
  title,
  desc,
  canAddManually = true,
  canSelectAll = false,
  setValue,
  hideState,
  overrideStyling,
  allowManualAdding = false,
  maxNumberToSelect = undefined,
  ...props
}) => {
  const [search, setSearch] = useState('')
  const [showAddInput, setShowAddInput] = useState(false)

  const [manualTaxonomyName, setManualTaxonomyName] = useState('')
  const [manualTaxonomies, setManualTaxonomies] = useState([])
  const [taxonomyExists, setTaxonomyExists] = useState(false)

  const { field } = useController({
    name,
    control,
  })

  const toggleTaxonomy = (event, { taxonomy }) => {
    if (field.value?.[taxonomy.id]) {
      field.onChange(
        removeUndefined({ ...field.value, [taxonomy.id]: undefined }),
      )
    } else {
      if (maxNumberToSelect && field.value && Object.values(field.value).length >= maxNumberToSelect)
        event.target.checked = !event.target.checked;
      else
        field.onChange({ ...field.value, [taxonomy.id]: taxonomy })
    }
  }

  const addManualTaxonomy = () => {
    const isTaxonomyNameExists = [...taxonomyItems, ...manualTaxonomies].some(
      taxonomyItem =>
        taxonomyItem.name.toLowerCase() ===
        manualTaxonomyName.toLowerCase().trim(),
    )
    if (isTaxonomyNameExists) {
      setTaxonomyExists(isTaxonomyNameExists)
      return
    }

    if (manualTaxonomyName) {
      const manualTaxonomy = {
        id: manualTaxonomyName,
        name: manualTaxonomyName,
        userAdded: true,
      }
      field.onChange({
        ...field.value,
        [manualTaxonomyName]: manualTaxonomy,
      })
      setManualTaxonomies([...manualTaxonomies, manualTaxonomy])
      setManualTaxonomyName('')
      setShowAddInput(false)
    }
  }

  const [allChecked, setAllChecked] = useState(false)

  const widthProps = overrideStyling ? '50%' : ''
  const containerProps = overrideStyling ? '' : 'sub-container'
  const wrapperProps = hideState ? '' : 'checkbox-wrapper'

  return (
    <div className={`${containerProps}`} style={{ width: `${widthProps}` }}>
      {!overrideStyling ? (
        <div>
          <h3 style={{ display: 'inline' }}>{title}</h3>
          <div style={{ display: 'inline' }} className="help">
            {helpImage ? (
              <a href="javascript:void(0)">
                <FaQuestionCircle />
                <span>
                  <img src={helpImage} alt="" />
                </span>
              </a>
            ) : null}
          </div>
          <br />
          <span
            style={{ fontSize: '0.75rem', opacity: '0.45', margin: '10px 0' }}
            className="description-text">
            {desc}
          </span>
          <br />{' '}
        </div>
      ) : null}
      {!hideState ? (
        <div className="search-container">
          <input
            className="sub-con-input"
            style={{ display: 'block' }}
            placeholder="Search"
            onChange={e => setSearch(e.target.value)}
            value={search}
          />
          <FaSearch className="search-icon" />
        </div>
      ) : null}
      {canSelectAll ? (
        <Button
          className="nav-button select-all-button"
          onClick={() => {
            if (allChecked) {
              setValue(name, undefined)
            } else {
              setValue(
                name,
                [...taxonomyItems, ...manualTaxonomies]?.reduce(
                  (obj, taxonomy) => {
                    obj[taxonomy.id] = taxonomy
                    return obj
                  },
                  {},
                ),
              )
            }

            setAllChecked(!allChecked)
          }}>
          {!allChecked ? 'Select All' : 'Unselect All'}
        </Button>
      ) : null}

      <div className={`${wrapperProps}`}>
        { topTaxonomyItems.length ? (
          <>
            <div className='checkbox-group'>
              {!hideState &&
                [...topTaxonomyItems]?.map(taxonomy => {
                  if (taxonomy?.name?.toLowerCase().includes(search.toLowerCase())) {
                    const checked = field.value?.[taxonomy.id]
                    return (
                      <div
                        key={taxonomy.id}
                        className="checkbox-container"
                        style={{ width: `${widthProps}` }}>
                        <label className={`data ${checked ? 'checked' : ''}`}>
                          <input
                            key={taxonomy}
                            className="taxonomyCheckbox"
                            type="checkbox"
                            checked={checked ?? false}
                            onChange={(event) =>
                              toggleTaxonomy(event, {
                                taxonomy: {
                                  ...taxonomy,
                                  userAdded: taxonomy.userAdded || false,
                                },
                              })
                            }
                          />
                          <span className="checkmark" />
                          {taxonomy.name}
                        </label>
                      </div>
                    )
                  }

                  return null
                })}
            </div>
            <hr/>
          </>
          ) : null
        }

        <div className='checkbox-group'>
        {!hideState &&
          [...taxonomyItems, ...manualTaxonomies]?.map(taxonomy => {
            if (taxonomy?.name?.toLowerCase().includes(search.toLowerCase())) {
              const checked = field.value?.[taxonomy.id]
              return (
                <div
                  key={taxonomy.id}
                  className="checkbox-container"
                  style={{ width: `${widthProps}` }}>
                  <label className={`data ${checked ? 'checked' : ''}`}>
                    <input
                      key={taxonomy}
                      className="taxonomyCheckbox"
                      type="checkbox"
                      checked={checked ?? false}
                      onChange={(event) =>
                        toggleTaxonomy(event, {
                          taxonomy: {
                            ...taxonomy,
                            userAdded: taxonomy.userAdded || false,
                          },
                        })
                      }
                    />
                    <span className="checkmark" />
                    {taxonomy.name}
                  </label>
                </div>
              )
            }

            return null
          })}
        </div>
      </div>
      {!hideState ? (
        <>
          <div style={{ height: '1rem' }}>
            {Object.keys(field.value || {}).length <= 0 && (
              <span className="errMsg">You need to select at least 1 choice</span>
            )}
          </div>
          <div style={{ height: '1rem' }}>
            {maxNumberToSelect && Object.keys(field.value || {}).length >= maxNumberToSelect && (
              <span className="errMsg">If you need to indicate more than { maxNumberToSelect } items, please, contact your assigned Manager</span>
            )}
          </div>
        </>
      ) : null}
      {canAddManually ? (
        <div className="addTaxonomyWrapper" style={{ marginTop: '20px' }}>
          {allowManualAdding ? (
            <div
              className="addWhatsMissingButton"
              onClick={() => setShowAddInput(!showAddInput)}>
              <IoAddCircleOutline color="#403488" size={17} />
              <span className="addWhatsMissing">Add what's missing</span>
            </div>
          ) : null}

          {showAddInput && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '10px',
              }}>
              <div style={{ display: 'flex', width: '450px' }}>
                <div className="addMissingTaxonomyInputContainer">
                  <input
                    autoFocus
                    className="addMissingTaxonomyInput"
                    name="addMissingTaxonomy"
                    type="text"
                    placeholder="Add what's missing"
                    onChange={e => {
                      setManualTaxonomyName(e.target.value)
                      if (taxonomyExists) {
                        setTaxonomyExists(false)
                      }
                    }}
                    value={manualTaxonomyName}
                  />
                  {!!manualTaxonomyName && (
                    <TiDelete
                      style={{ cursor: 'pointer' }}
                      color="lightgrey"
                      size={20}
                      onClick={() => setManualTaxonomyName('')}
                    />
                  )}
                </div>
                <button
                  className={classNames('addMissingTaxonomyButton', {
                    addMissingTaxonomyButtonActive: !!manualTaxonomyName,
                  })}
                  onClick={addManualTaxonomy}>
                  + Add
                </button>
                <button
                  className="deleteMissingTaxonomyButton"
                  onClick={() => {
                    setManualTaxonomyName('')
                    setShowAddInput(false)
                  }}>
                  Delete
                </button>
              </div>
              {taxonomyExists && manualTaxonomyName ? (
                <div>
                  <span className="errMsg">Such taxonomy already exists</span>
                </div>
              ) : null}
            </div>
          )}
        </div>
      ) : null}
    </div>
  )
}

export default TaxonomySelect
